@import "../../../globals/styles/variables.scss";

.typographyRoot {
  font-family: $font-family;
  margin: 0;

  // Align
  &.typographyAlignLeft {
    text-align: left;
  }
  &.typographyAlignCenter {
    text-align: center;
  }
  &.typographyAlignRight {
    text-align: right;
  }
  &.typographyAlignJustify {
    text-align: justify;
  }

  // Colors
  &.typographyColorPrimary {
    color: $primary;
  }
  &.typographyColorSecondary {
    color: $secondary;
  }
  &.typographyColorTerciary {
    color: $terciary;
  }
  &.typographyColorBlack{
    color: $black;
  }
  &.typographyColorWhite {
    color: $white;
  }
  &.typographyColorGray {
    color: #686868;
  }

  // FontWeight
  &.typographyFontWeightLight {
    font-weight: $font-weight-light;
  }
  &.typographyFontWeightRegular {
    font-weight: $font-weight-regular;
  }
  &.typographyFontWeightMedium {
    font-weight: $font-weight-medium;
  }

  // LineHeight
  &.typographyLineHeightH1 {
    line-height: $line-height-h1;
  }
  &.typographyLineHeightH2 {
    line-height: $line-height-h2;
  }
  &.typographyLineHeightH3 {
    line-height: $line-height-h3;
  }
  &.typographyLineHeightH3-small {
    line-height: $line-height-h3-small;
  }
  &.typographyLineHeightH4 {
    line-height: $line-height-h4;
  }
  &.typographyLineHeightH5 {
    line-height: $line-height-h5;
  }
  &.typographyLineHeightH5-small {
    line-height: $line-height-h5-small;
  }
  &.typographyLineHeightBody1 {
    line-height: $line-height-body1;
  }
  &.typographyLineHeightBody1-small {
    line-height: $line-height-body1-small;
  }
  &.typographyLineHeightBody2 {
    line-height: $line-height-body2;
  }
  &.typographyLineHeightCaption {
    line-height: $line-height-caption;
  }
  &.typographyLineHeightCaption-small {
    line-height: $line-height-caption-small;
  }

  // TextTransform
  &.typographyTextTransformCapitalize {
    text-transform: capitalize;
  }
  &.typographyTextTransformLowercase {
    text-transform: lowercase;
  }
  &.typographyTextTransformUppercase {
    text-transform: uppercase;
  }

  // Variant
  &.typographyVariantH1 {
    font-size: $font-size-h1;
  }
  &.typographyVariantH2 {
    font-size: $font-size-h2;
  }
  &.typographyVariantH3 {
    font-size: $font-size-h3;
  }
  &.typographyVariantH4 {
    font-size: $font-size-h4;
  }
  &.typographyVariantH5 {
    font-size: $font-size-h5;
  }
  &.typographyVariantBody1 {
    font-size: $font-size-body1;
  }
  &.typographyVariantBody2 {
    font-size: $font-size-body2;
  }
  &.typographyVariantCaption {
    font-size: $font-size-caption;
  }
  &.typographyVariantCaption-small {
    font-size: $font-size-caption-small;
  }

  // GuttterBottom
  &.typographyGutterBottom {
    margin-bottom: 0.35em;
  }

  // NoWrap
  &.typographyNoWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // Paragraph
  &.typographyParagraph {
    margin-bottom: 16px;
  }
}
