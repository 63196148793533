// Colors
$black: #000000;
$white: #FFFFFF;
$primary: #E67B0A;
$secondary: #9864E2;
$terciary: #009393;

// Shape
$border-radius-small: 15px;
$border-radius-medium: 40px;
$border-radius-large: 70px;

// FontFamily
$font-family: 'Rubik', sans-serif;

// FontWeight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;

// FontSize 
$font-size-h1: 60px;
$font-size-h2: 40px;
$font-size-h3: 34px;
$font-size-h4: 30px;
$font-size-h5: 24px;
$font-size-body1: 22px;
$font-size-body2: 20px;
$font-size-caption: 18px;
$font-size-caption-small: 17px;

// LineHeight
$line-height-h1: 67px;
$line-height-h2: 50px;
$line-height-h3: 52px;
$line-height-h3-small: 44px;
$line-height-h4: 40px;
$line-height-h5: 36px;
$line-height-h5-small: 28px;
$line-height-body1: 34px;
$line-height-body1-small: 26.63px;
$line-height-body2: 27px;
$line-height-caption: 28px;
$line-height-caption-small: 25px;