@import "../../../globals/styles/variables.scss";

.testimonialsSectionRoot {
  align-items: center;
  background: radial-gradient(
    81.67% 263.94% at 81.04% 26.84%,
    rgba(0, 147, 147, 0.15) 0%,
    rgba(0, 147, 147, 0.15) 19.23%,
    rgba(152, 100, 226, 0.15) 61.83%,
    rgba(233, 133, 42, 0.15) 100%
  );
  display: flex;
  flex-direction: column;
  gap: 83px;
  padding: 89px;
  position: relative;

  .testimonialsSectionTitle {
    max-width: 934px;
    width: 100%;

    color: #9864E2;
    text-align: center;
    font-weight: 400;
    line-height: 50px;
    font-size: 40px;
  }

  .testimonialsSectionSlider {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
  }

  .testimonialsSectionLink {
    max-width: 374px;
    width: 100%;
    z-index: 1;

    color: #FFFFFF;
    background: radial-gradient(
      87.02% 263.94% at 72.44% 26.84%,
      #009393 0%,
      #009393 19.23%,
      #9864E2 61.83%,
      #E9852A 100%
    );
    border-radius: 40px;
    font-size: 22px;
    font-weight: 500;
    line-height: 26.63px;
    min-height: 81px;
    padding: 0px 22px;
  }
  
  .testimonialsSectionPolygonBg {
    bottom: 0;
    height: 692px;
    position: absolute;
    right: 0;
    width: 606px;
  }

  @media screen and (max-width: 1024px) {
    padding: 40px;
  }

  // Estilos para tablets
  @media screen and (max-width: 768px) {
    gap: 30px;
    padding: 20px;

    .testimonialsSectionTitle {
      line-height: 36px;
      font-size: 28px;
    }

    .testimonialsSectionLink {
      margin-top: 30px;
      font-size: 16px;
      line-height: 20px;
      min-height: 51px;
      padding: 0px 14px;
    }

    .testimonialsSectionPolygonBg {
      display: none;
    }
  }

  // Estilos para dispositivos móviles
  @media screen and (max-width: 480px) {
    gap: 20px;

    .testimonialsSectionTitle {
      line-height: 32px;
      font-size: 24px;
    }

    .testimonialsSectionLink {
      font-size: 14px;
      line-height: 18px;
      min-height: 41px;
      padding: 0px 12px;
    }
  }
}