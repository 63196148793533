@import "../../../globals/styles/variables.scss";

.testimonyCardRoot {
  background-color: $white;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  display: inline-flex;
  height: 578px;
  width: 100%;
  position: relative;

  &.testimonyCardVariantOne {
    color: $primary;
  }

  &.testimonyCardVariantTwo {
    color: $secondary;
  }

  &.testimonyCardVariantThree {
    color: $terciary;
  }

  .testimonyCardContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 28px;
    padding: 38px 52px 52px 71px;

    .testimonyCardContentIcon {
      height: 46px;
    }

    .testimonyCardContentInfo {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 25px;
      justify-content: space-between;

      .testimonyCardContentInfoTestimony {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .testimonyCardContentInfoTestimonyTitle {
          color: inherit;
          font-weight: 400;
          line-height: 44px;
          font-size: 34px;
        }

        .testimonyCardContentInfoTestimonyDescription {
          letter-spacing: -1px;
          color: #000000;
          font-weight: 400;
          line-height: 25px;
          font-size: 17px;
        }
      }

      .testimonyCardContentInfoUser {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .testimonyCardContentInfoUserName {
          color: inherit;
          font-weight: 500;
          line-height: 36px;
          font-size: 24px;
        }

        .testimonyCardContentInfoUserOccupation {
          color: #000000;
          font-weight: 300;
          line-height: 25px;
          font-size: 17px;
        }
      }
    }
  }

  .testimonyCardMedia {
    display: block;
    object-fit: cover;
    width: 557px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
  }

  @media screen and (max-width: 1024px){
    height: 514px;

    .testimonyCardContent {
      padding: 20px 32px 32px 53px;

      .testimonyCardContentIcon {
        height: 30px;
      }
    }

    .testimonyCardMedia {
      width: 257px;
    }
  }

  // Estilos para tablets
  @media screen and (max-width: 768px) {
    height: 491px;

    .testimonyCardContent {
      .testimonyCardContentInfo {
        .testimonyCardContentInfoTestimony {
          .testimonyCardContentInfoTestimonyTitle {
            font-size: 24px;
            line-height: 34px;
          }
    
          .testimonyCardContentInfoTestimonyDescription {
            font-size: 14px;
            line-height: 22px;
          }
        }
  
        .testimonyCardContentInfoUser {
          .testimonyCardContentInfoUserName {
            font-size: 16px;
            line-height: 18px;
          }
    
          .testimonyCardContentInfoUserOccupation {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }

  // Estilos para dispositivos móviles
  @media screen and (max-width: 480px) {
    height: 340px;

    .testimonyCardContent {
      gap: 10px;
      padding: 20px 22px 22px 33px;

      .testimonyCardContentInfo {
        gap: 10px;

        .testimonyCardContentInfoTestimony {
          gap: 14px;
      
          .testimonyCardContentInfoTestimonyTitle {
            font-size: 16px;
            line-height: 26px;
            max-width: calc(100% - 78px);
          }
    
          .testimonyCardContentInfoTestimonyDescription {
            font-size: 11px;
            line-height: 18px;
          }
        }
  
        .testimonyCardContentInfoUser {
          .testimonyCardContentInfoUserName {
            font-size: 12px;
            line-height: 14px;
          }
    
          .testimonyCardContentInfoUserOccupation {
            font-size: 10px;
            line-height: 18px;
          }
        }
      }
    }

    .testimonyCardMedia {
      width: 100px;
      height: 100px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}