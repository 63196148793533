@import "./variables.scss";

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-size-adjust: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

strong, b {
  font-weight: $font-weight-medium;
}

body {
  background-color: $white;
  color: $black;
  font-family: $font-family;
  font-size: $font-size-body1;
  font-weight: $font-weight-regular;
  line-height: $line-height-body1;
  margin: 0px;
}

.slick-list {
  margin-bottom: 20px !important;
}

.slick-slide > div {
  margin: 0 42.5px;
}

.slick-dots li {
  height: 12px !important;
  margin: 0 8px !important;
  width: 12px !important;

  & button {
    height: 12px !important;
    width: 12px !important;

    &::before {
      font-size: 12px !important;
      height: 12px !important;
      line-height: 15px !important;
      width: 12px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .slick-list {
    margin-bottom: 10px !important;
  }

  .slick-slide > div {
    margin: 0 22.5px;
  }

  .slick-dots li {
    height: 10px !important;
    margin: 0 6px !important;
    width: 12px !important;
  
    & button {
      height: 10px !important;
      width: 10px !important;
  
      &::before {
        font-size: 10px !important;
        height: 10px !important;
        line-height: 13px !important;
        width: 10px !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .slick-slide > div {
    margin: 0 12.5px;
  }
}
