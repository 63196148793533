@import "../../../globals/styles/variables.scss";

.actionPresentationRoot {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 41px;
  width: 100%;

  .actionPresentationLink {
    justify-content: space-evenly;
    max-width: 972px;
    width: 100%;

    color: #FFFFFF;
    background: radial-gradient(
      87.02% 263.94% at 72.44% 26.84%,
      #009393 0%,
      #009393 19.23%,
      #9864E2 61.83%,
      #E9852A 100%
    );
    border-radius: 70px;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    min-height: 116px;
    padding: 0px 42px;

    .actionPresentationLinkKmImg {
      width: 300px;
      height: 61px;
    }

    .actionPresentationLinkTetheImg {
      width: 239px;
      height: 52px;
    }
  }

  // Estilos para tablets
  @media screen and (max-width: 768px) {
    padding: 20px;

    .actionPresentationLink {
      padding: 0px 30px;
      font-size: 24px;
      line-height: 34px;
      min-height: 96px;

      .actionPresentationLinkKmImg {
        width: 200px;
        height: 41px;
      }

      .actionPresentationLinkTetheImg {
        width: 159px;
        height: 35px;
      }
    }
  }

  // Estilos para dispositivos móviles
  @media screen and (max-width: 480px) {
    padding: 10px;

    .actionPresentationLink {
      padding: 0px 20px;
      font-size: 18px;
      line-height: 28px;
      min-height: 81px;

      .actionPresentationLinkKmImg {
        width: 150px;
        height: 31px;
      }

      .actionPresentationLinkTetheImg {
        width: 119px;
        height: 26px;
      }
    }
  }
}
