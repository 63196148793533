@import "../../../globals/styles/variables.scss";

.linkRoot {
  align-items: center;
  display: inline-flex;
  font-family: $font-family;
  justify-content: center;
  margin: 0;
  opacity: 1;
  text-decoration: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  // Variant
  &.linkVariantNormal {
    background: $white;
    color: $primary;
  }
  &.linkVariantDegrade {
    color: $white;
    background: radial-gradient(87.02% 263.94% at 72.44% 26.84%, #009393 0%, #009393 19.23%, #9864E2 61.83%, #E9852A 100%);
  }

  // Size
  &.linkSizeMedium {
    border-radius: $border-radius-medium;
    font-size: $font-size-body1;
    font-weight: $font-weight-medium;
    line-height: $line-height-body1-small;
    min-height: 81px;
    padding: 0px 22px;
  }
  &.linkSizeLarge {
    border-radius: $border-radius-large;
    font-size: $font-size-h4;
    font-weight: $font-weight-medium;
    line-height: $line-height-h4;
    min-height: 116px;
    padding: 0px 42px;
  }

  // Hover
  &:hover {
    opacity: 0.8;
  }
}
