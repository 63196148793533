@import "../../../globals/styles/variables.scss";

.heroRoot {
  align-items: center;
  background-image: 
    radial-gradient(
      81.67% 263.94% at 81.04% 26.84%, 
      rgba(0, 147, 147, 0.75) 0%,
      rgba(0, 147, 147, 0.75) 19.23%,
      rgba(152, 100, 226, 0.75) 61.83%,
      rgba(233, 133, 42, 0.75) 100%
    ),
    url('../../../../public/images/we-market.png');
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 700px;
  justify-content: center;
  padding: 40px;
  position: relative;
  width: 100%;

  .heroContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 848px;
    width: 100%;

    .heroContentHeader {
      align-items: center;
      display: flex;
      gap: 10px;

      .heroContentHeaderLogo {
        height: 121px;
        width: 150px;
      }

      .heroContentHeaderTitle {
        flex: 1;

        color: #FFFFFF;
        font-size: 60px;
        font-weight: 400;
        line-height: 67px;
        text-align: center;
      }
    }

    .heroContentDescription {
      margin-bottom: 70px;
      margin-top: 53px;
      max-width: 786px;

      color: #FFFFFF;
      font-size: 30px;
      font-weight: 300;
      line-height: 40px;
      text-align: center;
    }

    .heroContentLink {
      max-width: 325px;
      width: 100%;

      background: #FFFFFF;
      color: #E67B0A;

      border-radius: 40px;
      font-size: 22px;
      font-weight: 500;
      line-height: 26.63px;
      min-height: 81px;
      padding: 0px 22px;
    }
  }

  .heroPolygonBg {
    bottom: 0;
    height: 528px;
    position: absolute;
    right: 0;
    width: 465px;
  }

  // Estilos para tablets
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 20px;

    .heroContent {
      padding: 20px;
      text-align: center;

      .heroContentHeader {
        flex-direction: column;

        .heroContentHeaderLogo {
          margin-bottom: 10px;
        }

        .heroContentHeaderTitle {
          font-size: 40px;
          line-height: 50px;
        }
      }

      .heroContentDescription {
        margin-bottom: 30px;
        margin-top: 30px;
        font-size: 20px;
        line-height: 30px;
      }

      .heroContentLink {
        font-size: 18px;
        line-height: 22px;
        min-height: 61px;
        padding: 0px 16px;
      }
    }

    .heroPolygonBg {
      display: none;
    }
  }

  // Estilos para dispositivos móviles
  @media screen and (max-width: 480px) {
    padding: 10px;

    .heroContent {
      padding: 10px;

      .heroContentHeader {
        .heroContentHeaderTitle {
          font-size: 30px;
          line-height: 40px;
        }
      }

      .heroContentDescription {
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
      }

      .heroContentLink {
        font-size: 16px;
        line-height: 20px;
        min-height: 51px;
        padding: 0px 14px;
      }
    }
  }
}


