@import "../../../globals/styles/variables.scss";

.newFooterContainer {
  align-items: center;
  background: transparent linear-gradient(180deg, #F89737 0%, #EF6F11 100%) 0% 0% no-repeat padding-box;
  color: $white;
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  gap: 32px;
  width: 100%;
}

.footerContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px 174px;
  width: 100%;
}

.utilsContainer {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: space-between;
}

.utilsInfoContainer {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.43;
  margin: 0;
}

.utilsContactRow {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.columnContainerWithOutSpace {
  display: flex;
  flex-direction: column;
}

.utilsRrrssContainer {
  align-items: baseline;
  display: flex;
  flex-direction: column;
}

.divider {
  background-color: #C1C1C1;
  border: 0;
  flex-shrink: 0;
  height: 1px;
  margin: 0;
}

.columnSmallContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containerCenter {
  align-items: center;
}

.legalContainer {
  align-items: center;
  position: relative;
}

.afipLink {
  bottom: 0;
  left: 0;
  position: absolute;
}

.afipImg {
  height: 75px;
  width: 55px;
}

.privacyContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.privacyLink {
  color: inherit;
  text-decoration: none;
}

.caption {
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.66;
  margin: 0;
}

.socialNetworks {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.rrssLink {
  height: 32px;
  width: 32px;
}


@media screen and (max-width: 1024px) {
  .footerContent {
    padding: 40px 40px;
  }
}

@media screen and (max-width: 768px) {
  .footerContent {
    padding: 20px 20px;
  }

  .utilsContactRow {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 480px) {
  .utilsContainer {
    flex-direction: column;
    gap: 16px;
  }

  .columnContainer {
    gap: 16px;
  }

  .utilsInfoContainer {
    flex-direction: column;
    gap: 16px;

    & > div {
      text-align: center;
      width: 100%;
    }
  }

  .utilsContactRow {
    gap: 8px;
  }

  .utilsRrrssContainer {
    align-items: center;
    text-align: center;

    & > div {
      width: auto;
    }
  }

  .afipImg {
    height: 56px;
    width: 41px;
  }

  .copyright {
    max-width: 80%;
    text-align: center;
  }

  .privacyContainer {
    flex-direction: column;
    gap: 8px;
  }
}