@import "../../../globals/styles/variables.scss";

$list-row-spacing: 31px;
$list-column-spacing: 26px;

.questionsSectionRoot {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  gap: 78px;
  padding: 100px 174px;
  position: relative;

  .questionsSectionTitle {
    text-align: center;
    color: #009393;
    font-weight: 400;
    line-height: 50px;
    font-size: 40px;
  }

  .questionsSectionList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: calc($list-row-spacing / -2) calc($list-column-spacing / -2);
    z-index: 1;

    .questionsSectionListItem {
      min-width: 0;
      box-sizing: border-box;
      padding: calc($list-row-spacing / 2) calc($list-column-spacing / 2);
      flex-grow: 0;
      flex-basis: auto;
      width: calc(100% * 4 / 12);

      .questionsSectionListItemCard {
        align-items: center;
        background-color: $white;
        border-radius: $border-radius-small;
        box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        gap: 27px;
        height: 364px;
        padding: 24px 29px;
        width: 100%;

        .questionsSectionListItemIcon {
          width: 40px;
          height: 40px;
        }

        .questionsSectionListItemCardInfo {
          display: flex;
          flex-direction: column;
          gap: 17px;

          .questionsSectionListItemCardInfoQuestion {
            min-height: calc(28px * 2);

            text-align: center;
            color: #686868;
            font-weight: 400;
            line-height: 28px;
            font-size: 24px;
          }

          .questionsSectionListItemCardInfoAnswer {
            text-align: center;
            color: #000000;
            font-weight: 300;
            line-height: 25px;
            font-size: 17px;
          }
        }
      }
    }
  }

  .questionsSectionPolygonBgLeft {
    height: 399px;
    position: absolute;
    right: 0;
    top: 0;
    width: 406px;
  }

  .questionsSectionPolygonBgRight {
    bottom: 0;
    height: 804px;
    left: 0;
    position: absolute;
    width: 708px;
  }

  @media screen and (max-width: 1024px) {
    padding: 50px 40px;

    .questionsSectionList {
      .questionsSectionListItem {
        .questionsSectionListItemCard {
          height: 441px;

          .questionsSectionListItemCardInfo {
            .questionsSectionListItemCardInfoQuestion {
              min-height: calc(28px * 3);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 30px 20px;
    gap: 30px;

    .questionsSectionTitle {
      line-height: 36px;
      font-size: 28px;
    }

    .questionsSectionList {
      .questionsSectionListItem {
        width: calc(100% * 6 / 12);

        .questionsSectionListItemCard {
          height: 280px;
          gap: 17px;
          padding: 20px 25px;

          .questionsSectionListItemIcon {
            width: 30px;
            height: 30px;
          }

          .questionsSectionListItemCardInfo {
            .questionsSectionListItemCardInfoQuestion {
              min-height: calc(22px * 2);
  
              font-size: 18px;
              line-height: 22px;
            }
  
            .questionsSectionListItemCardInfoAnswer {
              font-size: 14px;
              line-height: 22px;
            }
          } 
        }
      }
    }

    .questionsSectionPolygonBgLeft,
    .questionsSectionPolygonBgRight {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    gap: 20px;
  
    .questionsSectionTitle {
      line-height: 32px;
      font-size: 24px;
    }

    .questionsSectionList {
      .questionsSectionListItem {
        width: calc(100% * 12 / 12);

        .questionsSectionListItemCard {
          height: 221px;
          gap: 10px;
          padding: 15px 20px;

          .questionsSectionListItemIcon {
            width: 20px;
            height: 20px;
          }

          .questionsSectionListItemCardInfo {
            .questionsSectionListItemCardInfoQuestion {
              min-height: calc(22px * 2);
  
              font-size: 15px;
              line-height: 29px;
            }
  
            .questionsSectionListItemCardInfoAnswer {
              font-size: 12px;
              line-height: 20px;
            }
          } 
        }
      }
    }
  }
}