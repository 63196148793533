@import "../../../globals/styles/variables.scss";

.detailsSectionRoot {
  background-color: rgba(152, 100, 226, 0.06);
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 100px 174px;

  .detailsSectionItem {
    align-items: center;
    display: flex;
    gap: 150px;

    &:nth-child(odd) {
      flex-direction: row;
      margin-left: 54px;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      margin-right: 54px;
    }

    .detailsSectionItemIcon {
      height: 389px;
    }

    .detailsSectionItemInfo {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 30px;

      .detailsSectionItemInfoTitle {
        color: #9864E2;
        font-weight: 400;
        line-height: 44px;
        font-size: 34px;
        text-align: left;
      }

      .detailsSectionItemInfoDescription {
        text-align: justify;
        color: #000000;
        font-weight: 400;
        line-height: 34px;
        font-size: 22px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    gap: 30px;
    padding: 40px;

    .detailsSectionItem {
      gap: 30px;

      &:nth-child(odd) {
        flex-direction: column;
        margin-left: 0;
      }
      &:nth-child(even) {
        flex-direction: column;
        margin-right: 0;
      }

      .detailsSectionItemInfo {
        gap: 20px;
        align-items: center;

        .detailsSectionItemInfoTitle {
          text-align: center;
        }
      }
    }
  }

  // Estilos para tablets
  @media screen and (max-width: 768px) {
    padding: 20px;

    .detailsSectionItem {
      .detailsSectionItemIcon {
        height: 239px;
      }

      .detailsSectionItemInfo {
        .detailsSectionItemInfoTitle {
          line-height: 26px;
          font-size: 24px;
        }

        .detailsSectionItemInfoDescription {
          line-height: 24px;
          font-size: 16px;
        }
      }
    }
  }

  // Estilos para dispositivos móviles
  @media screen and (max-width: 480px) {
    gap: 20px;

    .detailsSectionItem {
      gap: 20px;

      .detailsSectionItemIcon {
        height: 150px;
      }

      .detailsSectionItemInfo {
        .detailsSectionItemInfoTitle {
          line-height: 22px;
          font-size: 20px;
        }

        .detailsSectionItemInfoDescription {
          line-height: 20px;
          font-size: 14px;
        }
      }
    }
  }
}